import { Middleware } from '@nuxt/types'

const middleware: Middleware = ({ route, redirect }) => {
  // https://stackoverflow.com/a/63537296
  if (route.path !== '/' && route.path.endsWith('/')) {
    const { path, query, hash } = route
    const nextPath = path.replace(/\/+$/, '') || '/'
    const nextRoute = { path: nextPath, query, hash }
    redirect(nextRoute)
  }
}

export default middleware
