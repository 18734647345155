<template lang="pug">
footer.l-c_footer
  .l-container
    ul.c_footer-right
      li
        nuxt-link.external_link#footer_inquiry(to="/inquiry/new")
          | お問い合わせ
      li
        a.external_link#footer_career_inquiry(:href="`${$urls.career}/inquiry`") 採用担当の方へ
      li
        a.external_link#footer_career_terms(:href="`${$urls.career}/terms`") Vookキャリア利用規約
      li
        a.external_link#footer_career_law_document(:href="`${$urls.career}/law_document`") 有料職業紹介事業に関する情報開示について
    .c_footer-left
      ul
        li
          address
            | &copy;
            | {{ nowYear() }}
            | &nbsp;
            nuxt-link(to="/") Vook
            | .
        li
          nuxt-link.external_link#footer_vook(to="/") Vook TOP
        li
          a.external_link#footer_corporate(href="https://vook.co.jp/" target="_blank") 会社概要（運営会社）
      .number 有料職業紹介事業 許可番号：13 - ユ - 312611
</template>

<script>
export default {
  methods: {
    nowYear() {
      const now = new Date()
      return now.getFullYear()
    }
  }
}
</script>
<style lang="scss" scoped>
.l-c_footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  z-index: 3; // .c_index-contentより上
  border-top: 1px solid #dadada;
  background: #fff;
  font-size: 13px;
  @include media(sp) {
    position: relative;
    z-index: 11; //fixed bottom:0の要素より上にくるように
    padding: 20px 0;
  }
  .l-container {
    @include media(pc) {
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-direction: row-reverse;
      min-height: 90px;
    }
  }
  .c_footer-left {
    .number {
      margin-top: 10px;
      @include media(sp) {
        padding: 10px 0;
        border-top: 1px solid #dadada;
        border-bottom: 1px solid #dadada;
      }
    }
  }
  .c_footer-right {
    @include media(sp) {
      margin-bottom: 10px;
      padding-bottom: 10px;
      border-bottom: 1px solid #dadada;
    }
  }
  li {
    @include media(pc) {
      display: inline-block;
    }
  }
  li + li {
    @include media(pc) {
      margin-left: 20px;
    }
    @include media(sp) {
      margin-top: 5px;
    }
  }
  address {
    font-style: normal;
    padding-right: 20px;
    border-right: 1px solid #dadada;
  }
  a {
    text-decoration: none;
    transition: all 0.2s;
    @include media(sp) {
      padding: 5px 0;
    }
  }
  a:hover {
    color: rgba($dark_violet, 1);
  }
}
</style>
