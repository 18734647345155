export default ({
  nuxtState: {
    state: { auth }
  },
  app: { $sentry }
}) => {
  if (auth.loggedIn) {
    $sentry.configureScope((scope) => {
      scope.setUser({ id: auth.user.id })
    })
  }
}
