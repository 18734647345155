export const state = () => ({
  portfolios: [],
  portfolio: {},
  roles: [],
  cameras: [],
  makers: [],
  genres: [],
  apps: []
})

export const mutations = {
  setPortfolios(state, newPortfolios) {
    state.portfolios = newPortfolios
  },
  setPortfolio(state, newPortfolio) {
    state.portfolio = newPortfolio
  },
  setPortfolioLike(state, isLiked) {
    state.portfolio.liked = isLiked
  },
  incrementLikesCount(state) {
    state.portfolio.likes_count++
  },
  decrementLikesCount(state) {
    state.portfolio.likes_count--
  },
  setPortfolioClip(state, isClipped) {
    state.portfolio.clipped = isClipped
  },
  incrementClipsCount(state) {
    state.portfolio.clips_count++
  },
  decrementClipsCount(state) {
    state.portfolio.clips_count--
  },
  unlockPortfolio(state) {
    state.portfolio.locked = false
  },
  setCameras(state, cameras) {
    state.cameras = cameras
  },
  setRoles(state, roles) {
    state.roles = roles
  },
  setGenres(state, genres) {
    state.genres = genres
  },
  setApps(state, apps) {
    state.apps = apps
  },
  setMakers(state, makers) {
    state.makers = makers
  }
}

export const getters = {
  portfolios(state) {
    return state.portfolios.contents
  },
  totalCount(state) {
    return state.portfolios.total
  },
  page(state) {
    return state.portfolios.page
  },
  per(state) {
    return state.portfolios.per
  },
  currentPage(state) {
    return state.portfolios.page
  },
  isLastPage(state) {
    return state.portfolios.last_page
  },
  portfolio(state) {
    return state.portfolio
  },
  createdUser(state) {
    return state.portfolio.user
  },
  isLocked: (state, getters) => (user) => {
    if (!user) {
      return false
    }
    return !getters.isCreatedUser(user) && state.portfolio.locked
  },
  isCreatedUser: (_, getters) => (user) => {
    if (!user) {
      return false
    }
    return getters.createdUser.id === user.id
  },
  availableGenres(state) {
    return state.genres
  },
  availableRoles(state) {
    return state.roles
  },
  availableApps(state) {
    return state.apps
  },
  availableCameras(state) {
    return state.cameras
  },
  availableMakers(state) {
    return state.makers
  }
}

export const actions = {
  async fetchSelectableValues({ commit }) {
    const result = await this.$axios.$get('/portfolios/selectable_values')
    commit('setMakers', result.makers)
    commit('setCameras', result.cameras)
    commit('setGenres', result.genres)
    commit('setRoles', result.roles)
    commit('setApps', result.apps)
  },
  async fetchPortfolios({ commit }, params) {
    const result = await this.$axios.$get(`/portfolios/${params}`, {
      params
    })
    commit('setPortfolios', result)
  },
  async createPortfolio({ dispatch }, params) {
    const result = await this.$axios.$post('/portfolios', params)
    await dispatch('fetchPortfolios', result.id)
    return result
  },
  async updatePortfolio({ dispatch }, params) {
    const { id } = params
    const result = await this.$axios.$patch(`/portfolios/${id}`, params)
    await dispatch('fetchPortfolios', result.id)
    return result
  },
  async deletePortfolio({ commit, dispatch }, portfolioId) {
    await this.$axios.$delete(`/portfolios/${portfolioId}`)
  },
  async fetchPortfolio({ commit }, portfolioId) {
    const result = await this.$axios.$get(`/portfolios/${portfolioId}`)
    commit('setPortfolio', result)
  },
  async clipOn({ commit }, portfolioId) {
    await this.$axios.$post(`/portfolios/${portfolioId}/clip_on`)
    commit('setPortfolioClip', true)
    commit('incrementClipsCount')
  },
  async clipOff({ commit }, portfolioId) {
    await this.$axios.$delete(`/portfolios/${portfolioId}/clip_off`)
    commit('setPortfolioClip', false)
    commit('decrementClipsCount')
  },
  async likeOn({ commit }, portfolioId) {
    await this.$axios.$post(`/portfolios/${portfolioId}/like_on`)
    commit('setPortfolioLike', true)
    commit('incrementLikesCount')
  },
  async likeOff({ commit }, portfolioId) {
    await this.$axios.$delete(`/portfolios/${portfolioId}/like_off`)
    commit('setPortfolioLike', false)
    commit('decrementLikesCount')
  },
  async unlockRequest(_, portfolioId) {
    await this.$axios.$get(`/portfolios/${portfolioId}/unlock_request`)
  },
  async unlock({ commit }, portfolioId, params) {
    await this.$axios.$post(`/portfolios/${portfolioId}/unlock`, {
      params
    })
  }
}
