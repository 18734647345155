<template lang="pug">
div(@click="$store.commit('layout/init')")
  flash-message
  .l-career_form
    career-header
    Nuxt
</template>

<script>
import CareerHeader from '~/components/career/Header'
import FlashMessage from '~/components/FlashMessage'

export default {
  components: {
    CareerHeader,
    FlashMessage
  }
}
</script>
<style lang="scss" scoped>
::v-deep {
  .l-career_form {
    @include media(pc) {
      margin-bottom: -53px; // bodyの53pxを相殺（Footerを下固定用の記述）
    }
    .l-c_header {
      position: unset;
    }
    .l-c_header-pc,
    .l-c_header-sp {
      display: none !important;
    }
    .l-c_header-form {
      display: block; // 表示用
      padding: 20px 0;
      background: #fff;
      text-align: center;
      img {
        @include media(pc) {
          width: 152px;
          height: 28px;
        }
        @include media(sp) {
          width: 112px;
          height: 21px;
        }
      }
    }
  }
}
</style>
