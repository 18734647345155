





import { defineComponent, PropType, useRouter } from '@nuxtjs/composition-api'

export default defineComponent({
  props: {
    to: {
      type: String,
      required: true
    },
    onBeforeTransition: {
      type: Function as PropType<() => boolean>,
      default: () => true
    }
  },
  setup(props) {
    const $router = useRouter()

    const onClick = () => {
      if (props.onBeforeTransition()) {
        $router.push(props.to)
      }
    }

    return {
      onClick
    }
  }
})
