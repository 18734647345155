export const actions = {
  loginWithTwitter() {
    this.$axios
      .$get('/auth/twitter_authorize_url', {
        params: {
          callback_url: `${location.protocol}//${location.host}/users/auth/twitter/callback`
        }
      })
      .then((data) => {
        location.href = data.authorize_url
      })
      .catch(() => {
        alert('現在、X(Twitter)ログインはご利用いただけません。')
      })
  },
  loginWithFacebook() {
    this.$axios
      .$get('/auth/facebook_authorize_url', {
        params: {
          callback_url: `${location.protocol}//${location.host}/users/auth/facebook/callback`
        }
      })
      .then((data) => {
        location.href = data.authorize_url
      })
      .catch(() => {
        alert('現在、Facebookログインはご利用いただけません。')
      })
  },
  loginWithGoogle() {
    this.$axios
      .$get('/auth/google_authorize_url', {
        params: {
          callback_url: `${location.protocol}//${location.host}/users/auth/google_oauth2/callback`
        }
      })
      .then((data) => {
        location.href = data.authorize_url
      })
      .catch(() => {
        alert('現在、Googleログインはご利用いただけません。')
      })
  },
  loginWithInstagram() {
    this.$axios
      .$get('/auth/instagram_authorize_url', {
        params: {
          callback_url: `${location.protocol}//${location.host}/users/auth/instagram/callback`
        }
      })
      .then((data) => {
        location.href = data.authorize_url
      })
      .catch(() => {
        alert('現在、Instagramログインはご利用いただけません。')
      })
  }
}
