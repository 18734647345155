<template lang="pug">
div(@click="$store.commit('layout/init')")
  service-header
  flash-message
  nuxt
</template>

<script>
import ServiceHeader from '~/components/ServiceHeader'
import FlashMessage from '~/components/FlashMessage'

export default {
  components: {
    ServiceHeader,
    FlashMessage
  }
}
</script>
