








import { defineComponent, useContext, computed } from '@nuxtjs/composition-api'

const path = '/users/confirmation/new'

export default defineComponent({
  setup() {
    const { $auth, route } = useContext()
    const isShown = computed(
      () =>
        $auth.loggedIn &&
        $auth.user?.unconfirmed &&
        route.value.fullPath !== path
    )

    return {
      isShown,
      path
    }
  }
})
