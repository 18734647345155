const allowedIps = [
  '211.125.140.74', // SONY
  '211.125.140.64', // SONY
  '35.76.160.166', // s12m
  '39.110.216.86', // vook office(幸和ビル)
  '118.238.250.203' // vook office(宇田川町ビル4F)
]

export default ({ req, redirect }) => {
  if (!req) {
    return
  }

  // TODO: リクエストヘッダにアクセス元IPアドレスが含まれているか確認でき次第、ログ出力を削除
  console.log(req.headers)

  if (process.env.BASE_URL !== 'https://vook.vc') {
    return
  }

  if (!allowedIps.includes(req.headers['x-forwarded-for'])) {
    redirect('/')
  }
}
