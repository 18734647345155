<template lang="pug">
.container
  nuxt-error(:error="error")
  proxy-login-ribbon
</template>

<script>
import NuxtError from '~/components/NuxtError'
import ProxyLoginRibbon from '~/components/ProxyLoginRibbon'

export default {
  components: {
    NuxtError,
    ProxyLoginRibbon
  },
  props: {
    error: {
      type: Object,
      default: null
    }
  }
}
</script>
