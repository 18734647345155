export default ({ app, redirect, store, params }) => {
  const hasEnquete = store.getters['billing/hasEnquete']
  const cardId = store.getters['billing/cardId']
  const entryEvent = app.$cookies.get('entryEvent')
  if (entryEvent.entry_fee === 0) {
    return true
  } else if (!hasEnquete && cardId === '') {
    redirect(`/events/${params.slug}/credit_form`)
  }
}
