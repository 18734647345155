<template lang="pug">
div(@click="$store.commit('layout/init')")
  simple-header
  flash-message
  nuxt
  proxy-login-ribbon
</template>

<script>
import SimpleHeader from '~/components/SimpleHeader'
import FlashMessage from '~/components/FlashMessage'
import ProxyLoginRibbon from '~/components/ProxyLoginRibbon'

export default {
  components: {
    SimpleHeader,
    FlashMessage,
    ProxyLoginRibbon
  }
}
</script>

<style lang="scss">
body.confirm {
  padding-bottom: 0;
}
</style>
