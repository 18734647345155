<template lang="pug">
.l-bes(@click="$store.commit('layout/init')")
  FlashMessage
  nuxt
</template>

<script>
import FlashMessage from '~/components/FlashMessage'

export default {
  components: {
    FlashMessage
  }
}
</script>

<style lang="scss" scoped>
.l-bes {
  min-height: 100vh;
  background: #f0f0f0;
  color: #444;
  font-size: 16px;
  @include media(sp) {
    font-size: 14px;
  }
}
</style>
