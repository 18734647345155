<template lang="pug">
div(@click="$store.commit('layout/init')")
  service-header
  Header
  header-email-confirmation
  flash-message
  email-unregistered-warning
  nuxt
  Footer
  proxy-login-ribbon
</template>

<script>
import ServiceHeader from '~/components/ServiceHeader'
import Header from '~/components/Header'
import HeaderEmailConfirmation from '~/components/HeaderEmailConfirmation.vue'
import FlashMessage from '~/components/FlashMessage'
import EmailUnregisteredWarning from '~/components/EmailUnregisteredWarning'
import Footer from '~/components/Footer'
import ProxyLoginRibbon from '~/components/ProxyLoginRibbon'

export default {
  components: {
    ServiceHeader,
    Header,
    HeaderEmailConfirmation,
    EmailUnregisteredWarning,
    FlashMessage,
    Footer,
    ProxyLoginRibbon
  },
  watch: {
    // Lottieのアニメーションがブラウザのメモリリークを起こしている
    // それを防ぐため、アドベントページを強制的にリロードする
    $route() {
      if (
        this.$route.path === '/p/advent' ||
        this.$route.path === '/p/advent/1' ||
        this.$route.path === '/p/advent/2'
      ) {
        location.reload()
      }
    }
  }
}
</script>
