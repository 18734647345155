export default ({
  nuxtState: {
    state: { auth }
  },
  app: { $gtm }
}) => {
  if (auth.loggedIn) {
    $gtm.push({
      js: new Date()
    })
    $gtm.push({
      user_id: auth.user.id,
      user_status: auth.user.status
    })
    $gtm.push({
      event: 'user_status',
      user_status: auth.user.status,
      non_interaction: true
    })
  } else {
    $gtm.push({
      event: 'user_status',
      user_status: 'visitor',
      non_interaction: true
    })
  }
}
