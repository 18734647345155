export const state = () => ({
  tags: [],
  current: null,
  query: '',
  tag: '',
  sort: '',
  notes: { total: null },
  packages: { total: null },
  portfolios: { total: null },
  terms: [],
  searchTags: []
})

export const mutations = {
  updateSerchTags(state, newTags) {
    state.searchTags = newTags
  },
  updateTags(state, newTags) {
    state.tags = newTags
  },
  updateCurrent(state, newCurrent) {
    state.current = newCurrent
  },
  incrementCurrent(state) {
    state.current++
  },
  decrementCurrent(state) {
    state.current--
  },
  updateQuery(state, newquery) {
    state.query = newquery
  },
  updateTag(state, newTag) {
    state.tag = newTag
  },
  updateSort(state, newSort) {
    state.sort = newSort
  },
  updateNotes(state, newNotes) {
    state.notes = newNotes
  },
  updatePackages(state, newPackages) {
    state.packages = newPackages
  },
  updatePortfolios(state, newPortfolios) {
    state.portfolios = newPortfolios
  },
  updateTerms(state, newTerms) {
    state.terms = newTerms
  }
}

export const getters = {
  query(state) {
    return state.query
  },
  sort(state) {
    return state.sort
  },
  searchTags(state) {
    return state.searchTags
  }
}

export const actions = {
  async fetchNotes({ state, commit }, params) {
    const resultSet = await this.$axios.$get('/search/notes', {
      params
    })
    commit('updateNotes', resultSet)
  },
  async fetchPackages({ state, commit }, params) {
    const resultSet = await this.$axios.$get('/search/packages', {
      params
    })
    commit('updatePackages', resultSet)
  },
  async fetchPortfolios({ state, commit }, params) {
    const resultSet = await this.$axios.$get('/search/portfolios', {
      params
    })
    commit('updatePortfolios', resultSet)
  },
  async fetchTerms({ state, commit }, params) {
    const resultSet = await this.$axios.$get('/search/terms', {
      params
    })
    commit('updateTerms', resultSet)
  }
}
