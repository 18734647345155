<template lang="pug">
.tag-search
  input(ref="input" :value="query" @input="updateQuery($refs.input.value)" type="search" placeholder="キーワードで検索" autocomplete="off")
  button(@click="search()")
    icon-search-svg
  .suggestion-container(ref="suggestion" :class="{ 'is-active': active }")
    ul.suggestion-list
      li.suggestion-list-item(
        v-for="(tag, i) in tags"
        :key="i"
        :class="{ selected: i === current }"
        @mouseover="updateCurrent(i)"
        @click="search()")
        img.suggestion-list-item-icon(:src="tag.thumbnail.thumb_50.url")
        | {{ tag.name }}
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import IconSearchSvg from '~/assets/images/svg/icon_search.svg?inline'

export default {
  components: {
    IconSearchSvg
  },
  props: {
    type: {
      type: String,
      default: 'notes'
    },
    searchTags: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      active: false
    }
  },
  computed: {
    ...mapState('search', ['tags', 'current', 'query', 'sort'])
  },
  watch: {
    $route() {
      if (!this.$route.path.startsWith('/search')) {
        this.$store.commit('search/updateQuery', '')
      }
    },
    current(value) {
      if (value != null && this.tags[value]) {
        this.updateQuery(this.tags[value].name)
      }
    }
  },
  mounted() {
    this.$refs.input.addEventListener('focus', () => {
      this.active = true
    })
    this.$refs.input.addEventListener('blur', () => {
      setTimeout(() => {
        this.active = false
      }, 100) // クリックを補足するために少し待つ
    })

    let timer = 0
    this.$refs.input.addEventListener('keydown', (e) => {
      switch (e.keyCode) {
        case 38: // 上
          if (this.current == null || this.current === 0) {
            this.updateCurrent(this.tags.length - 1)
          } else if (this.current > 0) {
            this.decrementCurrent()
          }
          break
        case 40: // 下
          if (this.current == null || this.current === this.tags.length - 1) {
            this.updateCurrent(0)
          } else if (this.current < this.tags.length - 1) {
            this.incrementCurrent()
          }
          break
        case 37: // 左
        case 39: // 右
          // Nothing to do
          break
        case 13: // Enter
          this.search()
          break
        default:
          this.updateCurrent(null)
          if (timer > 0) {
            clearTimeout(timer)
          }
          timer = setTimeout(() => {
            this.fetchSuggestedTags()
          }, 200) // 最初の入力から少し待ってからAjax
          break
      }
    })
  },
  methods: {
    ...mapMutations('search', [
      'updateTags',
      'updateCurrent',
      'incrementCurrent',
      'decrementCurrent',
      'updateQuery'
    ]),
    async fetchSuggestedTags() {
      if (this.query.trim().length === 0) {
        return
      }
      const tags = await this.$axios.$get('/search/suggested_tags', {
        params: {
          phrase: this.query
        }
      })
      this.updateTags(tags)
      this.updateCurrent(null)
    },
    search() {
      this.$router.push({
        path: `/search/${this.type}`,
        query: {
          query: this.query,
          tags: this.searchTags,
          sort: this.sort
        }
      })
      this.$refs.input.blur()
      this.$store.commit('layout/init')
      this.$emit('isClose', false)
    }
  }
}
</script>

<style lang="scss" scoped>
.suggestion-container {
  display: none;
  position: absolute;
  z-index: 10;
  top: 100%;
  width: 100%;
  ul.suggestion-list {
    position: relative;
    z-index: 1;
    max-height: 45vh;
    margin-top: -1px;
    border: 1px solid $light_violet_border;
    border-top: none;
    border-radius: 0 0 $radius $radius;
    background: #fff;
    overflow-y: scroll;
    box-shadow: 0 0 10px rgba(#000, 0.1);
    li.suggestion-list-item {
      display: flex;
      align-items: center;
      min-height: 48px;
      padding: 0 10px;
      border-top: solid 1px #eee;
      &:hover {
        cursor: pointer;
      }
      &.selected {
        background: #eee;
      }
      .suggestion-list-item-icon {
        width: 28px;
        height: 28px;
        margin-right: 5px;
        &:not([src]) {
          display: none;
        }
      }
    }
  }
  &.is-active {
    display: block;
  }
}
</style>
