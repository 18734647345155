export default function({ $axios, redirect }) {
  $axios.onError((error) => {
    const code = parseInt(error.response && error.response.status)
    if (process.env.NODE_ENV !== 'production') {
      if (code === 401 && error.response.data.error_code === 'ban') {
        redirect('/users/sign_out')
      } else {
        throw error
      }
    }
    if (
      error.response.config.params &&
      error.response.config.params.note_link === 1
    ) {
      throw error
    }
    switch (code) {
      case 401:
        if (error.response.data.error_code === 'ban') {
          redirect('/users/sign_out')
          break
        }
        throw error
      case 403:
      case 409:
      case 422:
        throw error
      default:
        redirect(`/errors/${code}`)
        break
    }
  })
}
