import { Middleware } from '@nuxt/types'

const paths: { [oldPath: string]: string } = {
  '/note': '/contents',
  '/Vook_News/notes': '/serializations/1',
  '/series/iketeru_eizou': '/serializations/2',
  '/series/Weekly_MV': '/serializations/3',
  '/series/Weekly_CM': '/serializations/4',
  '/series/Weekly_KPOP': '/serializations/5',
  '/series/Cutters_Point': '/serializations/15',
  '/series/AE_Master': '/serializations/17',
  '/series/cmlab': '/serializations/18',
  '/series/youtuber_is_not_easy': '/serializations/21',
  '/series/b3d_lfhck': '/serializations/25'
}

const middleware: Middleware = ({ route, redirect }) => {
  const newPath = paths[route.path]
  if (newPath) {
    redirect(newPath, route.query)
  }
}

export default middleware
