import { OutputFormat, getResizeToFitUrl } from '@imagepix/client'

export function convertImage(
  url: string,
  width: number,
  height: number,
  format: OutputFormat
) {
  const path = new URL(url).pathname.replace(/^\//, '')
  return getResizeToFitUrl(path, { width, height, format, quality: 90 })
}

const useImagepix = ['production', 'staging'].includes(
  process.env.NODE_ENV ?? 'development'
)

export function convertAssetImage(
  path: string,
  width: number,
  height: number,
  format: OutputFormat
) {
  if (useImagepix) {
    path = path.replace(/^\//, '')
    return getResizeToFitUrl(path, { width, height, format, quality: 90 })
  } else {
    return path
  }
}
