export const state = () => ({
  isShowHeaderSubmitMenu: false,
  isShowHeaderLogoMenu: false,
  isShowHeaderSearch: false,
  flash: {
    type: null,
    message: null
  }
})

export const mutations = {
  init(state) {
    state.isShowHeaderSubmitMenu = false
    state.isShowHeaderLogoMenu = false
    state.isShowHeaderSearch = false
    state.flash.type = null
    state.flash.message = []
  },
  toggleHeaderSubmitMenu(state) {
    state.isShowHeaderSubmitMenu = !state.isShowHeaderSubmitMenu
    state.isShowHeaderLogoMenu = false
    state.isShowHeaderSearch = false
  },
  toggleHeaderLogoMenu(state) {
    state.isShowHeaderLogoMenu = !state.isShowHeaderLogoMenu
    state.isShowHeaderSubmitMenu = false
    state.isShowHeaderSearch = false
  },
  toggleHeaderSearch(state) {
    state.isShowHeaderSearch = !state.isShowHeaderSearch
    state.isShowHeaderSubmitMenu = false
    state.isShowHeaderLogoMenu = false
    if (state.isShowHeaderSearch) {
      document.querySelector('.header-search-form input[type="search"]').focus()
    }
  },
  showFlashNoticeMessage(state, newMessage) {
    state.flash.type = 'notice'
    state.flash.message = newMessage
  },
  showFlashAlertMessage(state, newMessage) {
    state.flash.type = 'alert'
    state.flash.message = newMessage
  },
  showFlashWarningMessage(state, newMessage) {
    state.flash.type = 'warning'
    state.flash.message = newMessage
  }
}
