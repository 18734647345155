<template lang="pug">
.l-error
  .l-container.is-md
    h1 {{ errorParts.message }}
    p(v-html="errorParts.description")
    ul.error-btn
      li
        nuxt-link.v-btn-round(:to="`/${errorParts.link}`")
          | {{ errorParts.linkName }}
      li
        a.v-btn-round-outline(@click="historyBack()")
          | 直前に見ていたページに戻る
</template>

<script>
export default {
  name: 'NuxtError',
  props: {
    error: {
      type: Object,
      default: () => {
        return {
          statusCode: 500,
          message: '500 Internal Server Error'
        }
      }
    }
  },
  data() {
    return {
      errorParts: {
        link: null,
        linkName: null,
        message: null,
        description: null
      }
    }
  },
  mounted() {
    switch (this.error.statusCode) {
      case 403:
      case 404:
        this.errorParts.message = 'ページが見つかりませんでした。'
        this.errorParts.description =
          'お探しのページは、削除されたか別の場所に移動した可能性がございます。'
        break
      case 500:
        this.errorParts.message = '500 Internal Server Error'
        this.errorParts.description =
          'ご不便をおかけし、申し訳ありません。<br>ただいま障害が発生しており、アクセスしようとしたページを表示できませんでした。<br>しばらく時間をおいてからもう一度アクセスしてください。'
        break
    }
    this.errorParts.link = ''
    this.errorParts.linkName = 'トップページへ戻る'
  },
  methods: {
    historyBack() {
      history.back()
    }
  },
  head() {
    return {
      title: this.errorParts.message
    }
  }
}
</script>

<style lang="scss">
.l-error {
  text-align: center;
  padding: 100px 0;
  @include media(sp) {
    padding: 60px 0;
  }
  h1 {
    font-size: 30px;
    font-weight: bold;
    margin-bottom: 40px;
    @include media(sp) {
      font-size: 20px;
      margin-bottom: 20px;
    }
  }
  p {
    margin-bottom: 20px;
    font-size: 18px;
    @include media(sp) {
      margin-bottom: 10px;
      font-size: 15px;
    }
  }
  .error-btn {
    margin-top: 40px;
    @include media(sp) {
      margin-top: 20px;
    }
    li {
      display: inline-block;
      @include media(sp) {
        display: block;
        a {
          min-width: 80%;
        }
      }
    }
    li + li {
      @include media(pc) {
        margin-left: 20px;
      }
      @include media(sp) {
        margin-top: 10px;
      }
    }
  }
}
</style>
