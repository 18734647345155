export default ({
  app,
  $axios,
  nuxtState: {
    state: { auth }
  }
}) => {
  app.router.afterEach((to, from) => {
    if (auth.loggedIn) {
      $axios.$patch('/users/access')
    }
  })
}
