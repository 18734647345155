<template lang="pug">
div(@click="$store.commit('layout/init')")
  .l-header(style="padding: unset;")
  flash-message
  nuxt
  proxy-login-ribbon
</template>

<script>
import FlashMessage from '~/components/FlashMessage'
import ProxyLoginRibbon from '~/components/ProxyLoginRibbon'

export default {
  components: {
    FlashMessage,
    ProxyLoginRibbon
  }
}
</script>
