export const state = () => ({
  // kind: 1:レンタル
  payment: {
    kind: null,
    price: null,
    name: null,
    description: null,
    image: {},
    kindObj: {}
  },
  plan: {
    name: null,
    payjp_plan_id: null,
    price: null,
    temp_name: null,
    user: {
      email: null,
      password: null
    }
  },
  entry_event: {
    last_name: null,
    first_name: null,
    last_name_furi: null,
    first_name_furi: null,
    gender: null,
    phone_number: null,
    postal_code: null,
    prefecture_code: null,
    city_address: null,
    mansion_name: null,
    age: null,
    birthdate: null,
    tmp_birthdate: null,
    occupation_id: null,
    occupation_other: null,
    affiliation: null,
    department: null,
    job_title: null,
    remark: null,
    vgt_day: null,
    user_kind: null,
    loading: false,
    entry_fee: null,
    entry_fee_name: null
  },
  enquete: {
    video_production_years: null,
    video_production_process: [],
    video_production_process_other: null,
    main_camera: null,
    main_camera_model: null,
    sub_camera: null,
    sub_camera_model: null,
    other_equipment: null,
    lens_brands: [],
    lens_names: [],
    main_pc_brand: null,
    main_pc_brand_other: null,
    main_pc_price: null,
    frequently_used_production_tools: [],
    frequently_used_production_tools_other: null,
    want_to_use_production_tools: [],
    want_to_use_production_tools_other: null,
    main_video_genre: null,
    main_video_genre_other: null,
    want_video_genre: null,
    want_video_genre_other: null,
    where_to_buy_equipment: [],
    where_to_buy_equipment_real_other: null,
    where_to_buy_equipment_online_other: null,
    issues: [],
    issues_other: null,
    online_contents: [],
    online_contents_other: null
  },
  cardId: '',
  createdId: 0,
  cardInfo: {
    token: null,
    last4: null,
    exp_month: null,
    exp_year: null
  }
})

export const mutations = {
  setPayment(state, newPayment) {
    state.payment = newPayment
  },
  setToken(state, newToken) {
    state.token = newToken
  },
  setPlan(state, newPlan) {
    state.plan = newPlan
  },
  setCardId(state, newCardId) {
    state.cardId = newCardId
  },
  setCreatedId(state, newCreatedId) {
    state.createdId = newCreatedId
  },
  setCardInfo(state, newCardInfo) {
    state.cardInfo = newCardInfo
  },
  updateEntryAttributes(state, newAttributes) {
    const { attribute, data } = newAttributes
    state.entry_event[attribute] = data
  },
  updateEnqueteAttributes(state, newAttributes) {
    const { attribute, data } = newAttributes
    state.enquete[attribute] = data
  }
}

const isAllFalsyValues = (value) => {
  return (
    Object.values(value)
      .filter(Boolean)
      // eslint-disable-next-line arrow-parens
      .filter((enqueteValue) => enqueteValue.length).length > 0
  )
}
export const getters = {
  payment(state) {
    return state.payment
  },
  plan(state) {
    return state.plan
  },
  enquete(state) {
    return state.enquete
  },
  cardId(state) {
    return state.cardId
  },
  createdId(state) {
    return state.createdId
  },
  cardInfo(state) {
    return state.cardInfo
  },
  entry_event(state) {
    return state.entry_event
  },
  hasEnquete(state) {
    return isAllFalsyValues(state.enquete)
  },
  hasEntry(state) {
    return isAllFalsyValues(state.entry_event)
  },
  canSetInitializeEntryEvent(_state, getters) {
    return !getters.hasEnquete && !getters.hasEntry
  }
}
