/* eslint-disable camelcase */
import { useContext } from '@nuxtjs/composition-api'

export default () => {
  const { $axios } = useContext()

  const fetchProfile = () =>
    $axios.$get<careers.Profile | null>('/careers/profile')

  const updateProfile = (input: object) =>
    $axios.$put<careers.Profile>('/careers/profile', input)

  return {
    fetchProfile,
    updateProfile
  }
}
