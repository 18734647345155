export const state = () => ({
  notes: [],
  note: {}
})

export const mutations = {
  setNote(state, newNote) {
    state.note = newNote
  },
  setNotes(state, newNotes) {
    state.notes = newNotes
  },
  setNoteLike(state, isLiked) {
    state.note.liked = isLiked
  },
  incrementLikesCount(state) {
    state.note.likes_count++
  },
  decrementLikesCount(state) {
    state.note.likes_count--
  },
  setNoteClip(state, isClipped) {
    state.note.clipped = isClipped
  },
  setNoteComments(state, newComments) {
    state.note.comments = newComments
  },
  incrementClipsCount(state) {
    state.note.clips_count++
  },
  decrementClipsCount(state) {
    state.note.clips_count--
  }
}

export const getters = {
  notes(state) {
    return state.notes.contents
  },
  infeedAdFrame(state) {
    return state.note.infeedAdFrame
  },
  relatedNotes(state) {
    return state.note.relatedNotes.contents
  },
  comments(state) {
    return state.note.comments
  },
  note(state) {
    return state.note
  },
  totalCount(state) {
    return state.notes.total
  },
  page(state) {
    return state.notes.page
  },
  per(state) {
    return state.notes.per
  },
  currentPage(state) {
    return state.notes.page
  },
  isLastPage(state) {
    return state.notes.last_page
  },
  createdUser(state) {
    return state.note.user
  },
  isCreatedUser: (_, getters) => (user) => {
    if (!user) {
      return false
    }
    return getters.createdUser.id === user.id
  },
  isCommentLikedUser: () => (comment, user) => {
    return comment.liking_user_ids.includes(user.id)
  },
  isCommentCreatedUser: () => (comment, user) => {
    if (!user || !comment.user) {
      return false
    }
    return user.id === comment.user.id
  }
}

export const actions = {
  async fetchNotes({ commit }, params) {
    const result = await this.$axios.$get('/notes', {
      params
    })
    commit('setNotes', result)
  },
  async createNote({ commit }, params) {
    const result = await this.$axios.$post('/notes', params)
    commit('setNote', result)
    return result
  },
  async updateNote({ commit }, params) {
    const { id } = params
    const result = await this.$axios.$patch(`/notes/${id}`, params)
    commit('setNote', result)
    return result
  },
  async destroyNote({ commit }, params) {
    const { id } = params
    await this.$axios.$delete(`/notes/${id}`)
  },
  async clipOn({ commit }, noteId) {
    await this.$axios.$post(`/notes/${noteId}/clip_on`)
    commit('setNoteClip', true)
    commit('incrementClipsCount')
  },
  async clipOff({ commit }, noteId) {
    await this.$axios.$delete(`/notes/${noteId}/clip_off`)
    commit('setNoteClip', false)
    commit('decrementClipsCount')
  },
  async likeOn({ commit }, noteId) {
    await this.$axios.$post(`/notes/${noteId}/like_on`)
    commit('setNoteLike', true)
    commit('incrementLikesCount')
  },
  async likeOff({ commit }, noteId) {
    await this.$axios.$delete(`/notes/${noteId}/like_off`)
    commit('setNoteLike', false)
    commit('decrementLikesCount')
  },
  async likeOnComment({ commit }, { noteId, commentId }) {
    await this.$axios.$post(`/notes/${noteId}/comments/${commentId}/like_on`)
    const comments = await this.$axios.$get(`/notes/${noteId}/comments`)
    commit('setNoteComments', comments)
  },
  async likeOffComment({ commit }, { noteId, commentId }) {
    await this.$axios.$delete(`/notes/${noteId}/comments/${commentId}/like_off`)
    const comments = await this.$axios.$get(`/notes/${noteId}/comments`)
    commit('setNoteComments', comments)
  },
  async destroyComment({ commit }, { noteId, commentId }) {
    await this.$axios.$delete(`/notes/${noteId}/comments/${commentId}`)
    const comments = await this.$axios.$get(`/notes/${noteId}/comments`)
    commit('setNoteComments', comments)
  }
}
