import { isValidDate } from './dayjs'

export const dateYmd = {
  validate(value) {
    if (/^[0-9]{4}[0-9]{2}[0-9]{2}$/.test(value)) {
      return isValidDate(value, 'YYYYMMDD')
    } else {
      return false
    }
  },
  message(field) {
    return `${field}は実在する年月日でなければなりません。`
  }
}

export const date = {
  validate(value) {
    if (/^[0-9]{4}年[0-9]{2}月[0-9]{2}日/.test(value)) {
      const dateText = value.replace(/[年月]/g, '-').replace('日', '')
      return isValidDate(dateText, 'YYYY-MM-DD')
    } else {
      return false
    }
  },
  message(field) {
    return `${field}は実在する年月日でなければなりません。`
  }
}

export const dateHyphen = {
  validate(value) {
    if (/^[0-9]{4}-[0-9]{2}-[0-9]{2}/.test(value)) {
      return isValidDate(value, 'YYYY-MM-DD')
    } else {
      return false
    }
  },
  message(field) {
    return `${field}は実在する年月日でなければなりません。`
  }
}

export const requiredObject = {
  validate(value) {
    return Object.entries(value).length > 0
  },
  message(field) {
    return `${field}を選択してください。`
  }
}

export const postalCode = {
  validate(value) {
    return /^[0-9]{7}$/.test(value)
  },
  message(field) {
    return `${field}は半角数字7桁で入力してください。`
  }
}

export const tel = {
  validate(value) {
    return /^([0-9]{2,4}-?[0-9]{2,4}-?[0-9]{2,4})|なし$/.test(value)
  },
  message(field) {
    return `${field}を正しく入力してください。`
  }
}

export const url = {
  validate(value) {
    try {
      // eslint-disable-next-line no-new
      new URL(value)
      return /^https?:\/\/.+$/.test(value)
    } catch {
      return false
    }
  },
  message(field) {
    return `${field}を正しく入力してください。`
  }
}

export const acceptance = {
  validate(value) {
    return !!value
  },
  message(field) {
    return `<strong>${field}</strong>に同意してください。`
  }
}

export const maxCount = {
  validate(array, [maxCount]) {
    return array.length <= maxCount
  },
  message(field, { 0: maxCount }) {
    return `${field}は最大${maxCount}個までです。`
  }
}
