

















































































import {
  defineComponent,
  useRoute,
  useContext,
  ref,
  watch,
  onMounted
} from '@nuxtjs/composition-api'
import VookLink from '~/components/VookLink.vue'
import useCookies from '~/composables/useCookies'
import useProfileApis from '~/composables/careers/useProfileApis'

export default defineComponent({
  components: {
    VookLink
  },
  setup() {
    const $cookies = useCookies()
    const route = useRoute()
    const { $auth } = useContext()
    const { fetchProfile } = useProfileApis()

    const saveCurrentPath = () => {
      $cookies.set('afterLoginURL', `${process.env.CAREER_URL}/registration`)
      const path = location.pathname
      if (!/(sign_in|sign_up)$/.test(path)) {
        $cookies.set('afterCareerRegistrationPath', path)
      }

      return true
    }

    const profile = ref<careers.Profile | null>(null)

    // TODO 暫定処理。リファクタリング対象。ページ遷移するたびに処理が走るので得策ではない。
    // storeでデータ取得か$authの中にキャリア一次登録のデータを入れるのが良いと思われる
    const getCareerUserData = () => {
      if ($auth.loggedIn) {
        fetchProfile().then((data: careers.Profile | null) => {
          profile.value = data
        })
      }
    }

    watch(
      () => route.value,
      () => getCareerUserData()
    )

    onMounted(() => {
      getCareerUserData()
    })

    return {
      profile,
      saveCurrentPath
    }
  },
  data() {
    return {
      positionTop: 0,
      positionMenu: 800
    }
  },
  mounted() {
    document.onscroll = (e) => {
      this.positionTop =
        document.documentElement.scrollTop || document.body.scrollTop
    }
  }
})
