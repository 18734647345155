import dayjs from 'dayjs'

const allowedPaths = ['/users/information', '/users/sign_out']

export default ({ $auth, app: { $cookies }, route, redirect }) => {
  if (!$auth.loggedIn) {
    return
  }

  // ↓のリリース前から存在するユーザには入力を強制しない
  // https://www.notion.so/vookinc/PF-fd8498f33e59468c9d25a9a33b7837f2?pvs=4
  if (dayjs($auth.user.created_at).isBefore('2023-09-27T15:00:00+09:00')) {
    return
  }

  if (
    !allowedPaths.includes(route.fullPath) &&
    !$auth.user.personal_detail_registered
  ) {
    if (!$cookies.get('afterPersonalDetailRegistrationURL')) {
      const afterPersonalDetailRegistrationPath = route.query.ref
        ? route.query.ref
        : route.fullPath
      $cookies.set(
        'afterPersonalDetailRegistrationURL',
        afterPersonalDetailRegistrationPath
      )
    }
    redirect('/users/information')
  }
}
