/* eslint-disable */

import Vue from 'vue'
import { ValidationProvider, ValidationObserver, extend, localize } from 'vee-validate'
import * as rules from 'vee-validate/dist/rules'
import * as customRules from '~/libs/validation-custom-rules'
import ja from 'vee-validate/dist/locale/ja'
const payform = require('payform')

Vue.component('ValidationProvider', ValidationProvider)
Vue.component('ValidationObserver', ValidationObserver)

Object.keys(rules).forEach((rule) => {
  extend(rule, rules[rule])
})

Object.keys(customRules).forEach((rule) => {
  extend(rule, customRules[rule])
})

ja.messages.numeric = '{_field_}は半角数字のみ使用できます'
ja.messages.regex = '{_field_}の形式が正しくありません'
Object.keys(ja.messages).forEach((rule) => {
  ja.messages[rule] = ja.messages[rule].replace('{_field_}', '<strong>{_field_}</strong>')
})
localize('ja', {
  ...ja,
  names: {
    nickname: 'ニックネーム',
    name: 'お名前',
    facebook_url: 'Facebookアカウント',
    twitter_url: 'X(Twitter)アカウント',
    instagram_url: 'Instagramアカウント',
    web_url: 'Webページ',
    tel: '電話番号',
    profile: 'プロフィール',
    portfolio1_url: 'ポートフォリオのURL',
    portfolio2_url: 'ポートフォリオのURL',
    portfolio1_involve: 'ポートフォリオへの関わり方',
    portfolio2_involve: 'ポートフォリオへの関わり方',
    title: 'タイトル',
    videoUrl: '動画URL',
    launchedAt: '作品公開月',
    launchedAtYear: '作品公開年',
    launchedAtMonth: '作品公開月',
    jobRegistered: '利用規約'
  },
  fields: {
    jobRegistered: {
      required: '<strong>{_field_}</strong>と<strong>プライバシーポリシー</strong>に同意をいただけない場合は、Vook求人をご利用いただくことはできません。'
    },
    title: {
      required: '<strong>{_field_}</strong>を入力してください'
    },
    videoUrl: {
      required: '<strong>{_field_}</strong>を入力してください'
    },
    launchedAtYear: {
      required: '<strong>{_field_}</strong>を選択してください'
    },
    launchedAtMonth: {
      required: '<strong>{_field_}</strong>を選択してください'
    },
    facebook_url: {
      regex: '<strong>{_field_}</strong>には https://www.facebook.com/ ではじまるURLを入力してください'
    },
    twitter_url: {
      regex: '<strong>{_field_}</strong>には https://twitter.com/ ではじまるURLを入力してください' // TODO:https://x.comが普及し始めたら変更
    },
    instagram_url: {
      regex: '<strong>{_field_}</strong>には https://www.instagram.com/ ではじまるURLを入力してください'
    },
    web_url: {
      regex: '<strong>{_field_}</strong>にはhttpから始まるURLを入力してください'
    },
    name: {
      required: '<strong>{_field_}</strong>を入力してください'
    },
    profile: {
      required: '<strong>{_field_}</strong>を入力してください'
    },
    portfolio1_url: {
      required: '<strong>{_field_}</strong>を入力してください'
    },
    portfolio2_url: {
      required: '<strong>{_field_}</strong>を入力してください'
    },
    portfolio1_involve: {
      required: '<strong>{_field_}</strong>を入力してください'
    },
    portfolio2_involve: {
      required: '<strong>{_field_}</strong>を入力してください'
    }
  }
})

const youtube = /https:\/\/www\.youtube\.com\/watch\?v=/
const youtu = /https:\/\/youtu\.be\//
const vimeo = /https:\/\/vimeo\.com\//
const facebook = /https:\/\/www\.facebook\.com\//
const videoUrlFormat = {
  message: '<strong>動画URL</strong>が正しくありません',
  validate(value) {
    return youtube.test(value) || youtu.test(value) || vimeo.test(value) || facebook.test(value)
  }
}
extend('video_url_format', videoUrlFormat)
/* eslint-enable */

const creditCardNumberFormat = {
  message: '<strong>カード番号</strong>が無効です',
  validate(value) {
    return payform.validateCardNumber(value)
  }
}
extend('credit_card_number_format', creditCardNumberFormat)

const creditCardCVCFormat = {
  message: '<strong>CVC</strong>が無効です',
  validate(value) {
    return payform.validateCardCVC(value)
  }
}
extend('credit_card_cvc_format', creditCardCVCFormat)

const creditCardExpiryFormat = {
  message: '<strong>有効期限の日付</strong>が無効です',
  validate(value) {
    const dateArray = value.split('/')
    if (dateArray.length < 2) {
      return false
    }
    const month = dateArray[0].trim()
    const year = dateArray[1].trim()
    return payform.validateCardExpiry(month, year)
  }
}
extend('credit_card_expiry_format', creditCardExpiryFormat)
