<template lang="pug">
.email_unregistered(v-if="$auth.loggedIn && $auth.user.email.endsWith('@example.com')")
  .l-container
    nuxt-link(to="/mypage/account")
      icon-mail-svg
      .pc
        strong メールアドレスを登録
        | すると、イベントの参加や記事の投稿などの機能が利用できるようになります。
      .sp メールアドレスが未設定です。
      .email_unregistered-btn
        arrow-right-svg
        .pc メールアドレスを登録する
        .sp 登録する
</template>
<script>
import IconMailSvg from '~/assets/images/svg/icon_mail.svg?inline'
export default {
  components: {
    IconMailSvg
  }
}
</script>
<style lang="scss">
.email_unregistered {
  text-align: center;
  border-bottom: 1px solid #eee;
  padding: 10px 0;
  background: #fff;
  vertical-align: middle;
  color: $violet;
  line-height: 1;
  font-size: 13px;
  a {
    display: inline-block;
    color: $violet;
    &:hover {
      opacity: 0.8;
    }
  }
  @include media(pc) {
    .pc {
      display: inline-block;
    }
  }
  @include media(sp) {
    font-size: 12px;
    padding: 8px 0;
    .sp {
      display: inline-block;
    }
  }
  strong {
    font-weight: bold;
  }
  svg {
    display: inline-block;
    vertical-align: middle;
    width: 27px;
    height: 27px;
    margin-right: 3px;
    fill: none;
    stroke: $violet;
    stroke-width: 1px;
    @include media(sp) {
      width: 24px;
      height: 24px;
    }
  }
  &-btn {
    display: inline-block;
    color: $violet;
    margin-left: 10px;
    text-decoration: underline;
    svg {
      width: 9px;
      height: 9px;
      stroke-width: 2px;
      margin-right: 5px;
    }
  }
}
</style>
