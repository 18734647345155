export default ({ $auth, app, store, route, redirect }) => {
  if (!$auth.loggedIn) {
    const afterLoginPath = route.query.ref ? route.query.ref : route.fullPath
    app.$cookies.set('afterLoginURL', afterLoginPath)
    app.$cookies.set('flashAlertMessage', 'ログインしてください。')

    // 今いるサービスのログイン画面にリダイレクトさせる
    if (route.fullPath.startsWith('/businesses/')) {
      redirect('/businesses/sign_in')
      return
    }
    redirect('/users/sign_in')
  }
}
