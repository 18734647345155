<template lang="pug">
.v-alert
  .is-notice(v-if="$store.state.layout.flash.type === 'notice'")
    icon-check-svg
    div(v-html="$store.state.layout.flash.message")
  .is-alert(v-if="$store.state.layout.flash.type === 'alert'")
    icon-error-svg
    div(v-html="$store.state.layout.flash.message")
  .is-warning(v-if="$store.state.layout.flash.type === 'warning'")
    icon-warning-svg
    div(v-html="$store.state.layout.flash.message")
</template>

<script>
import IconCheckSvg from '~/assets/images/svg/icon_check.svg?inline'
import IconErrorSvg from '~/assets/images/svg/icon_error.svg?inline'
import IconWarningSvg from '~/assets/images/svg/icon_warning.svg?inline'

export default {
  components: {
    IconCheckSvg,
    IconErrorSvg,
    IconWarningSvg
  },
  data() {
    return {
      timer: null
    }
  },
  watch: {
    $route(_to, _from) {
      // For SPA
      this.showMessageUsingCookie()
    }
  },
  created() {
    // For SSR
    this.showMessageUsingCookie()
  },
  mounted() {
    // FIXME: @nuxtjs/composition-api を使うと store.commit しても状態が更新されないため、暫定的に対処
    this.timer = setInterval(this.showMessageUsingCookie, 200)
  },
  beforeDestroy() {
    clearInterval(this.timer)
  },
  methods: {
    showMessageUsingCookie() {
      const notice = this.$cookies.get('flashNoticeMessage')
      const warning = this.$cookies.get('flashWarningMessage')
      const alert = this.$cookies.get('flashAlertMessage')

      if (notice) {
        this.$store.commit('layout/showFlashNoticeMessage', notice)
      }
      if (warning) {
        this.$store.commit('layout/showFlashWarningMessage', warning)
      }
      if (alert) {
        this.$store.commit('layout/showFlashAlertMessage', alert)
      }

      this.$cookies.remove('flashNoticeMessage')
      this.$cookies.remove('flashWarningMessage')
      this.$cookies.remove('flashAlertMessage')
    }
  }
}
</script>
