export default ({ $auth, redirect }) => {
  if (!$auth.loggedIn) {
    redirect('/')
  }

  const VOOK_USERS = ['Vook_editor', 'Vook_AfterEffects', 'Vook_PremierePro']

  if (!VOOK_USERS.includes($auth.user.username)) {
    redirect('/')
  }
}
