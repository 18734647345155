<template lang="pug">
.l-career(@click="$store.commit('layout/init')")
  service-header
  .l-career-sticky
    flash-message
    career-header
  Nuxt
  career-footer
</template>

<script>
import ServiceHeader from '~/components/ServiceHeader'
import CareerHeader from '~/components/career/Header'
import CareerFooter from '~/components/career/Footer'
import FlashMessage from '~/components/FlashMessage'

export default {
  components: {
    ServiceHeader,
    CareerHeader,
    CareerFooter,
    FlashMessage
  }
}
</script>
<style lang="scss" scoped>
.l-career {
  @include media(pc) {
    padding-bottom: 90px; // footerの90px（Footerを下固定用の記述）
  }
  &-sticky {
    position: sticky;
    top: 0;
    z-index: 100;
  }
}
</style>
