export const state = () => ({
  enquete: null
})

export const mutations = {
  setEnquete(state, newEnquete) {
    state.enquete = newEnquete
  }
}

export const getters = {
  enquete(state) {
    return state.enquete
  }
}

export const actions = {
  async fetchEnquete({ commit }, params) {
    try {
      const result = await this.$axios.$get(`/enquetes/${params}`)
      commit('setEnquete', result)
    } catch ({ response }) {
      response.data.status = response.status
      commit('setEnquete', response.data)
    }
  }
}
