import { Middleware } from '@nuxt/types'

const middleware: Middleware = ({ route, app }) => {
  const afterLoginPath = route.query.afterLoginPath
  if (afterLoginPath) {
    app.$cookies.set('afterLoginURL', afterLoginPath)
  }
}

export default middleware
