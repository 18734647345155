<template lang="pug">
div(@click="$store.commit('layout/init')")
  header
    logo-vook-svg
      title Vook
    nuxt-link.logout.v-btn-outline-sm(to="/users/sign_out") ログアウト
  flash-message
  nuxt
  proxy-login-ribbon
</template>

<script>
import FlashMessage from '~/components/FlashMessage'
import ProxyLoginRibbon from '~/components/ProxyLoginRibbon'
import LogoVookSvg from '~/assets/images/svg/logo_vook.svg?inline'

export default {
  components: {
    FlashMessage,
    ProxyLoginRibbon,
    LogoVookSvg
  }
}
</script>

<style lang="scss" scoped>
header {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  border-bottom: 1px solid #eee;
  background: #fff;
  height: $simple_header_height;
  line-height: 0;
  svg {
    width: 60px;
    height: 21px;
    fill: #000;
  }
  .logout {
    position: absolute;
    right: 20px;
    top: 50%;
    transform: translateY(-50%);
    @include media(sp) {
      right: 10px;
    }
  }
}
</style>
